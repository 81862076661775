<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CAlert :show="showLoginErrorAlert" color="danger"
                    >Please check your login credentials</CAlert
                  >
                  <CInput
                    placeholder="Username"
                    autocomplete="username email"
                    v-model="email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" @click="login"
                        >Login</CButton
                      >
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0 invisible"
                        >Forgot password?</CButton
                      >
                      <CButton color="link" class="d-lg-none"
                        >Register now!</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h1>Knights Booking</h1>
                <img
                  src="../../assets/icons/knights-logo-full-white.png"
                  :height="50" 
                />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import HTTPRequest from "../../controllers/httpRequest.js";
import encryptStorage from "../../controllers/encryptStorage.js";
import Fingerprint2 from "fingerprintjs2";
import { mapMutations, mapState } from "vuex";

var settings = {};
var uniqueId = "";

export default {
  name: "Login",
  computed: {
    ...mapState(["endPoint"]),
  },
  data: function () {
    return {
      email: "",
      password: "",
      showLoginErrorAlert: false,
      showSessionExistsToaster: true,
    };
  },
  mounted: function () {
    var _this = this
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        Fingerprint2.get(settings, async function (components) {
          var values = components.map(function (component) { return component.value });
          uniqueId = Fingerprint2.x64hash128(values.join(''), 31); 
          var sessionExists = await HTTPRequest.getRequest(_this.endPoint, "/api/v1/user_sessions/session_exists?unique_id=" + uniqueId, null, null)
          if(sessionExists.logged_in == true && encryptStorage.getItem("loggedIn") == true ){
            encryptStorage.setItem("sessionExists", true);
            _this.$router.push("/dashboard");
          } else {
            encryptStorage.clear();
          }
        })
      })
    }     
  },
  methods: {
    ...mapMutations(["set"]),
    login: async function () {
      var authenticate = await this.authenticate();
      
      if (authenticate.access_token) {
        encryptStorage.setItem("refreshToken", authenticate.refresh_token);
        encryptStorage.setItem("accessToken", authenticate.access_token);
        var isEligible = await this.checkEligibility();
        if (isEligible) {
          var userSession = await this.storeUserSession();
          if (userSession.id > 0) {
            encryptStorage.setItem("loggedIn", true);
            encryptStorage.setItem("sessionExists", false);
            this.showLoginErrorAlert = false;
            this.$router.push("/dashboard");
          } else {
            this.showLoginErrorAlert = true;
          }
        } else {
            this.showLoginErrorAlert = true;
        }
      } else {
        this.showLoginErrorAlert = true;
      }
    },
    async checkEligibility(){
      var result = await HTTPRequest.getRequest(this.endPoint, "/api/v1/users/login_eligible?from=web", null);
      return result.eligible;
    },
    async authenticate(){
      var authenticateBody = {email: this.email, password: this.password, grant_type: "password", app_type: 'v1-web-app'};
      var authenticate = await HTTPRequest.postRequest(this.endPoint, "/api/v1/auth/token", null, authenticateBody);
      return authenticate;
    },
    async createBackendSession() {
      var userSessionBody = {unique_id: uniqueId};
      var userSession = await HTTPRequest.postRequest(this.endPoint, "/api/v1/user_sessions/create", null, userSessionBody);
      return userSession;
    },
    async storeUserSession(){
      var userSession = await this.createBackendSession();
      if (userSession.user_id > 0) {
        var user = await HTTPRequest.getRequest(this.endPoint, "/api/v1/users/"+userSession.user_id , null, null);
        encryptStorage.setItem("loggedInUserFirstName", user.name);
        encryptStorage.setItem("loggedInUserSurname", user.surname);
        if (user.is_submitter == true) {
          encryptStorage.setItem("loggedInUserRole", 'submitter');
        } else {
          encryptStorage.setItem("loggedInUserRole", user.role);
        }
        encryptStorage.setItem("loggedInUserId", user.id);
      }
      return userSession;
    }
  },
};
</script>
